import React, { FC } from 'react';

import { URL } from 'constant';

import { Avatar } from 'core/components';
import { IResult } from '../model/types';
import { IOfficeMapRoom } from 'entities/office-map/types';
import {
	IContact,
	IDepartment,
	IFAQ,
	IGuide,
	IInventoryLocation,
	IInventoryWarehouse,
	IObjects,
	IPost,
	IRole,
	IUser,
	IUserPos,
} from '../model/resultTypes';

// Возможные типы поиска:
// ARTICLE
// CATEGORY_CONTACT
// CONTACT
// DEPARTMENT
// ALL_DEPARTMENT
// MAXI-CLUB-PARTNER
// POST
// SERVICE_CATEGORY
// USER_POSITION
// USER_NOT_FIRE_NOT_CONTACT
// USER
// WIKI_ROLE
// WIKI_DOCUMENT
// WIKI_FAQ
// WIKI_GLOSSARY
// WIKI_GUIDE
// REALTY_OBJECT
//INVENTORY_LOCATION
//INVENTORY_WAREHOUSE
//USER_MATERIALLY_RESPONSIBLE

export const ResultToList: FC<any> = ({ resultList, selectHandle }) => {
	const renderContact = (list: IContact[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Контакты</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() =>
									selectHandle({
										id: item.userId,
										phone: item.telephone,
										name: item.name,
										position: item.position,
										address: item.address,
										email: item.emailUser,
										avatarFile: item.avatarFile,
									})
								}
							>
								<div className="finder-field-result-item__avatar">
									<Avatar
										image={item.avatarFile ? `${URL}${item.avatarFile}` : null}
										type="user"
										size="small"
									/>
								</div>

								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
									<span className="finder-field-result-item__position">
										{item.position}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderUser = (list: IUser[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Пользователи</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() =>
									selectHandle({
										id: item.id,
										phone: item.telephone,
										name: `${item.surname} ${item.name} ${
											item.middlename ? item.middlename : ''
										}`,
										position: item.position,
										address: item.address,
										email: item.emailUser,
										avatarFile: item.avatarFile,
									})
								}
							>
								<div className="finder-field-result-item__avatar">
									<Avatar
										image={item.avatarFile ? `${URL}${item.avatarFile}` : null}
										type="user"
										size="small"
									/>
								</div>

								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">{`${item.name} ${item.surname}`}</span>
									<span className="finder-field-result-item__position">
										{item.position}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderRole = (list: IRole[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Роли</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderDepartment = (list: IDepartment[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Подразделения</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderAllDepartment = (list: IDepartment[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Подразделения</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderPost = (list: IPost[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Посты</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.title}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderFAQ = (list: IFAQ[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">FAQ</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() =>
									selectHandle({
										id: item.id,
										name: item.question,
									})
								}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.question}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderGuide = (list: IGuide[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Гид по компании</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderUserPosition = (list: IUserPos[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Должности</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
									<span className="finder-field-result-item__position">
										{item?.departmentName}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderTag = (list: any, multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Теги</p>}
				{list.length
					? list.map((item: string, index: number) => (
							<div
								className="finder-field-result-item"
								key={index}
								onClick={() => {
									selectHandle({
										id: item,
										name: item,
									});
								}}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">{item}</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderInventoryLocation = (
		list: IInventoryLocation[],
		multiType: boolean,
		key: number
	) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Местоположение</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() =>
									selectHandle({
										id: item.id,
										name: `${item.address}, ${item.room}`,
									})
								}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.address}, {item.room}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderInventoryWarehouse = (
		list: IInventoryWarehouse[],
		multiType: boolean,
		key: number
	) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Местоположение</p>}
				{list.length
					? list.map(item => (
							<div
								className="finder-field-result-item"
								key={item.id}
								onClick={() => selectHandle(item)}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.name}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderOfficeMap = (list: IOfficeMapRoom[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Карта офиса</p>}
				{list.length
					? list.map((item, index) => (
							<div
								className="finder-field-result-item"
								key={index}
								onClick={() => {
									selectHandle({
										...item,
										id: item.id,
										name: item.number,
									});
								}}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										<b>{item.number}</b>, {item.description}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderObject = (list: IObjects[], multiType: boolean, key: number) => {
		return (
			<div className="finder-field-result-wrap" key={key}>
				{multiType && <p className="finder-field-result-title">Объекты</p>}
				{list.length
					? list.map((item, index) => (
							<div
								className="finder-field-result-item"
								key={index}
								onClick={() => {
									selectHandle({
										...item,
										id: item.id,
										name: item.address,
									});
								}}
							>
								<div className="finder-field-result-item__right">
									<span className="finder-field-result-item__name">
										{item.address}
									</span>
								</div>
							</div>
					  ))
					: renderEmpty()}
			</div>
		);
	};

	const renderEmpty = () => {
		return (
			<p className="empty-result" key={0}>
				По Вашему запросу ничего не найдено
			</p>
		);
	};

	const renderResult = (item: IResult, multiType: boolean, key: number) => {
		switch (item.indexName) {
			case 'CONTACT':
				return renderContact(item.objects, multiType, key);
			case 'USER':
				return renderUser(item.objects, multiType, key);
			case 'WIKI_ROLE':
				return renderRole(item.objects, multiType, key);
			case 'DEPARTMENT':
				return renderDepartment(item.objects, multiType, key);
			case 'ALL_DEPARTMENT':
				return renderAllDepartment(item.objects, multiType, key);
			case 'POST':
				return renderPost(item.objects, multiType, key);
			case 'WIKI_FAQ':
				return renderFAQ(item.objects, multiType, key);
			case 'WIKI_GUIDE':
				return renderGuide(item.objects, multiType, key);
			case 'USER_POSITION':
				return renderUserPosition(item.objects, multiType, key);
			case 'TAG':
				return renderTag(item.objects, multiType, key);
			case 'INVENTORY_LOCATION':
				return renderInventoryLocation(item.objects, multiType, key);
			case 'INVENTORY_WAREHOUSE':
				return renderInventoryWarehouse(item.objects, multiType, key);
			case 'USER_MATERIALLY_RESPONSIBLE':
				return renderUser(item.objects, multiType, key);
			case 'OFFICE_MAP_ROOM':
				return renderOfficeMap(item.objects, multiType, key);
			case 'REALTY_OBJECT':
				return renderObject(item.objects, multiType, key);
			default:
				return null;
		}
	};

	return (
		<div className="finder-field-result">
			{resultList.map((item: IResult, index: number, arr: IResult[]) => {
				return renderResult(item, arr.length > 1, index);
			})}
		</div>
	);
};
